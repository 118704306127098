<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
import Vue from 'vue'

export default {
  created(){
    let surl = window.location.href
    var obj = {};
    var urls = surl.substr(surl.indexOf('?') + 1).split('&');
    for (var i = 0; i < urls.length; i++) {
      var temp = urls[i].split('=');
      obj[temp[0]] = temp[1]
    }
    if(obj.projectId){
      sessionStorage.setItem('projectId', obj.projectId)
    }
    if(obj.type){
      sessionStorage.setItem('type', obj.type || 1)
    }
    if(obj.token && obj.companyName){
      sessionStorage.setItem('token', obj.token)
      sessionStorage.setItem('companyName', decodeURI(decodeURI(obj.companyName)))

      Vue.ls.set("page",1)
    }else if(sessionStorage.getItem('token')){
      console.log(sessionStorage.getItem('token'))
    }
    else{
      let hrefU = window.location.href
      let url = null
      if(hrefU.indexOf('bigscreenqdtest') > -1){
        url = 'https://nllcbtest.lanlingcb.com/'
      }else if(hrefU.indexOf('prebigscreenqd') > -1){
        url = 'https://prenllcb.kaishuihu.com/'
      }else{
        url = 'https://nllcb.kaishuihu.com/'
      }
      window.location.replace(url)
    }
  },
}
</script>
<style>
*{
  padding: 0;
  margin: 0;
}
body{
  /* width: 100%;
  height: 100%; */
  line-height: 1.15;
  margin: 0;
  padding: 0;
  background-repeat: no-repeat;
  background-position: 0 0 / cover;
  background-color: #fff;
}
body::-webkit-scrollbar {
  display: none;
}
/* body::-moz-scrollbar {
  display: none;
}
body::-ms-scrollbar {
  display: none;
}
body::-o-scrollbar {
  display: none;
} */
html {
scrollbar-width: none;
}
/* 
html::-webkit-scrollbar {
display: none;
} */


#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.pagetion{
  display: flex;
  justify-content: flex-start;
}
.mr-10{
  margin-right: 0.10rpx;
}
.fontsize18{
  /* font-size: 0.18rem; */
}

</style>
