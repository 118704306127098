import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'screen',
    component: () => import('../views/srceen/index.vue'),
  },
  // {
  //   path: '/',
  //   name: 'login',
  //   component: () => import('../views/srceen/login.vue'),
  // },
  {
    path: '/table',
    name: 'table',
    component: () => import('../views/srceen/table.vue'),
  },
  {
    path: '/salary',
    name: 'salary',
    component: () => import('../views/salary/salary.vue'),
  },
  {
    path: '/construction',
    name: 'salary',
    component: () => import('../views/construction/index.vue'),
  },
  {
    path: '/construction/test',
    name: 'test',
    component: () => import('../views/construction/test.vue'),
  },
  {
    path: '/managerScreen',
    component: () => import('../views/detection/index.vue'),
    redirect:'/managerScreen/groupManager',
    children:[
      {
        path: 'groupManager',
        component: () => import('../views/detection/groupManaget.vue'),
      },

      // {
      //   path: '/managerScreen/detail',
      //    component: () => import('../views/detection/attendanceDetails.vue'),
      //   meta: { title: "考勤详情" },
      // },

      {
        path: '/managerScreen/rectification',
         component: () => import('../views/detection/rectificationOrder.vue'),
        meta: { title: "整改单" },
      },
      {
        path: '/managerScreen/rewardPunish',
         component: () => import('../views/detection/rewardPunishList.vue'),
        meta: { title: "奖罚单" },
      },
      {
        path: '/managerScreen/assessment',
         component: () => import('../views/detection/assessment.vue'),
        // component: (resolve) =>
        //   require(["@/views/bigScreen/assessment"], resolve),
        meta: { title: "下级考核" },
      },
      {
        path: '/managerScreen/detail',
        component: () => import('../views/detection/attendanceDetails.vue'),
    
        meta: { title: "考勤详情" },
      },
    ]
  },
  
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
