import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Storage from 'vue-ls';
import Element from 'element-ui'
// import { message } from 'ant-design-vue';
// import 'ant-design-vue/dist/antd.css';
import { Message} from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import qs from "qs";
Vue.prototype.$qs = qs;
// console.log("环境: ", process.env.NODE_ENV)
// 引入 echarts 核心模块，核心模块提供了 echarts 使用必须要的接口。
import * as echarts from 'echarts/core';
// 引入柱状图图表，图表后缀都为 Chart
import {
  BarChart,
  LineChart 
} from 'echarts/charts';
// 引入提示框，标题，直角坐标系组件，组件后缀都为 Component
import {
  TitleComponent,
  TooltipComponent,
  GridComponent
} from 'echarts/components';
// 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
import {
  CanvasRenderer
} from 'echarts/renderers';
import { ToolboxComponent } from 'echarts/components';
// 注册必须的组件
echarts.use(
  [TitleComponent, TooltipComponent, GridComponent, BarChart, CanvasRenderer , ToolboxComponent ,LineChart ]
)

// 引入dataV
import dataV from '@jiaminghi/data-view'

Vue.use(dataV)
Vue.config.productionTip = false;
Vue.prototype.$message = Message;
let options = {
  namespace: '', // key键前缀
  name: 'ls', // 命名Vue变量.[ls]或this.[$ls],
  storage: 'local', // 存储名称: session, local, memory
};
// Vue.use(Tooltip)
require('echarts/lib/chart/pie')
require('echarts/lib/component/tooltip')
require('echarts/lib/component/graphic')
require('echarts/lib/component/legend')
Vue.config.productionTip = false
Vue.prototype.$echarts = echarts
Vue.use(Storage, options);
Vue.use(Element)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
